import "./App.css";
import { Route, Routes } from "react-router-dom";
import PageHome from "./PageHome";
import PageTrack from "./PageTrack";
import { useEffect, useState } from "react";

function App() {
  // Fetch Data
  const [datas, setDatas] = useState([]);
  async function fetchData() {
    const gSheetUrl =
      "https://script.google.com/macros/s/AKfycby0epaq8I6EW_GuXNqTYMq-RbQ4z-s9Hf3VW7vI84DB10F1AO8Vj3l_krudwHQcnVcy/exec";

    const bnToolUrl =
      "https://bn-tool-git-api-trackno-euw-chaivanons-projects.vercel.app/api/trackno";
    const bnToolHeader = new Headers();
    bnToolHeader.append("B-API-KEY", "3iGN45Y58Ftes");
    const bnToolOptions = {
      method: "GET",
      headers: bnToolHeader,
      // mode: "no-cors",
      redirect: "follow",
    };

    const resGSheetPrepare = fetch(gSheetUrl);
    const resBnToolPrepare = fetch(bnToolUrl, bnToolOptions);
    const [resGSheet, resBnTool] = await Promise.all([
      resGSheetPrepare,
      resBnToolPrepare,
    ]);

    const resGSheetJsonPrepare = resGSheet.json();
    const resBnToolJsonPrepare = resBnTool.json();
    const [resGSheetJson, resBnToolJson] = await Promise.all([
      resGSheetJsonPrepare,
      resBnToolJsonPrepare,
    ]);

    setDatas([...resBnToolJson, ...resGSheetJson]);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<PageHome />} />
        <Route path="/:trackSearch" element={<PageTrack datas={datas} />} />
      </Routes>
    </div>
  );
}

export default App;
